<template>
  <div class="personalInfoReported"></div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
</style>